<template>
  <div class="change-p" style="background-image: url(https://images.unsplash.com/photo-1526367790999-0150786686a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80)">
    <div class="logo-box">
      <img src="../../assets/images/logo.svg" alt="Webmagnat Logo">
    </div>
    <b-form @submit.prevent="recover" class="change-p__form">
      <b-form-group
        class="recover-form-group"
        ><b-form-input
          id="change-p-first"
          v-model="form.password"
          type="password"
          required
          placeholder=""
          class="recover-form__input"
        ></b-form-input>
        <label for="change-p-first"> {{ $t('recover_password.password') }} </label>
      </b-form-group>
      <b-form-group
        class="recover-form-group"
        ><b-form-input
          id="change-p-second"
          v-model="form.repeatPassword"
          type="password"
          required
          placeholder=""
          class="recover-form__input"
        ></b-form-input>
        <label for="change-p-second"> {{ $t('recover_password.repeat_password') }} </label>
      </b-form-group>
      <div class="recover-form__button-container">
        <b-button class="app-button login-button recover-form__button" type="submit"> {{ $t('recover_password.change_password') }} </b-button>
      </div>
      <p class="form-error" v-if="error"> {{ errorMessage }} </p>
      <p class="form-success" v-if="success"> {{ $t('recover_password.success') }} </p>
    </b-form>
  </div>
</template>

<script>
import RecoverService from './../../api-services/recover.service'
export default {
  data () {
    return {
      form: {
        password: '',
        repeatPassword: ''
      },
      error: false,
      errorMessage: '',
      success: false
    }
  },
  methods: {
    recover () {
      if (this.form.password.length > 0 && this.form.password === this.form.repeatPassword) {
        let data = {
          hash: this.$route.query.hash,
          password: this.form.password
        }
        this.error = false
        this.success = false
        RecoverService.changePassword(data)
          .then((response) => {
            this.success = true
            this.resetForm()
            setTimeout(() => {
              this.success = false
              this.$router.push('/login')
            }, 3000)
          })
          .catch((error) => {
            this.errorMessage = error.response.data.password ? error.response.data.password[0] : 'A aparut o problema. Va rugam sa incercati mai tarziu'
            this.error = true
            this.resetForm()
          })
      } else {
        this.errorMessage = this.$t('recover_password.not_match')
        this.error = true
        this.resetForm()
      }
    },
    resetForm () {
      this.form.password = ''
      this.form.repeatPassword = ''
    }
  },
  created () {
    this.$store.dispatch('hideLoading')
  }
}
</script>

<style scoped>

</style>
