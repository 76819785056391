import axios from 'axios'

export default {
  recover (data) {
    return axios.post('/auth/recover', data)
  },
  changePassword (data) {
    return axios.post('/auth/recover-password', data)
  }
}
